import {
  ref,
  onMounted, getCurrentInstance,
  reactive
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  setup() {
    const app = getCurrentInstance()
    const {
      // $confirm,
      $strInd,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const data = ref([])
    // const totalRecords = ref(0)
    const inputSearch = ref(null)
    // const row = ref(10)
    const sendInputSearch = ref('')
    const router = useRouter()
    const filter = reactive({
      search: '',
      totalRecords: 0,
      pageNumber: 1,
      pageSize: 10,
      orderby: 'createdat desc',
      voucherType: 'Barang'
    })

    const getData = async () => {
      store.dispatch('showLoading')
      filter.search = sendInputSearch.value ? sendInputSearch.value : ''
      console.log('filter', filter)
      const response = await voucherDiscountUseCase.fetchHistoryRedeemVoucher(filter)
      console.log('hasil get', response)
      if (!response.error) {
        filter.totalRecords = response.result.Count
        filter.pageSize = response.result.PageSize
        data.value = response.result.Data
      } else {
        console.log(response)
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const searchData = (val: any) => {
      sendInputSearch.value = (isEmpty(val) ? '' : val).toLowerCase()
      console.log(sendInputSearch.value)
      filter.pageNumber = 1
      filter.pageSize = 10
      getData()
    }

    const printRedeem = (val: any) => {
      const redeemId = val
      const query = `BuktiKlaimVoucherRetail?IdTransVoucher=${redeemId}`

      // console.log('query', query)
      const routeData = router.resolve({ name: 'report', params: { Id: encryptDecriptMethods.encrypt(query), jenisReport: 'redeem-souvenir' } })
      window.open(routeData.href, '_blank')
    }

    const onPage = (evt: any) => {
      filter.pageNumber = evt.page + 1
      getData()
    }

    onMounted(() => {
      getData()
    })

    return {
      data,
      filter,
      searchData,
      inputSearch,
      // row,
      // totalRecords,
      moment,
      printRedeem,
      onPage,
      route
    }
  }
}
